import React from "react";
import styled from "styled-components";

import { Divider, Icon, Menu, MenuItem, MenuList } from "@material-ui/core";
import {
    Edit as EditIcon,
    Help as HelpIcon,
    Person as PersonIcon,
    Redo as RedoIcon,
    Undo as UndoIcon
} from "@material-ui/icons";

import { ActionPermissionsObject } from "common/interfaces";
import { getStaticUrl } from "js/config";
import WorkspaceController from "js/controllers/WorkspaceController";
import AppController from "js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { trackActivity } from "js/core/utilities/utilities";
import PresentationEditorController, { PanelType } from "js/editor/PresentationEditor/PresentationEditorController";
import { app } from "js/namespaces";
import { ShowConvertedToClassicDialog, ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import FeedbackDialog from "js/react/components/Dialogs/FeedbackDialog";
import SwitchTemplateDialog from "js/react/views/SwitchTemplate/SwitchTemplateDialog";

const logger = getLogger(LogGroup.PPT_ADDIN);

const Container = styled.div`
    position: absolute;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 50px;
    background: #222222;

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;  
`;

const LogoAndButtonsContainer = styled.div`
    width: 33%;

    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;  
`;

const LogoContainer = styled.a`
    display: block;
    width: 68px;
    height: 50px;
    background: #000000;

    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;  

    >img {
        width: 24px;
        height: 24px;
    }
`;

function getIconButton(Icon, style = {}) {
    const strippedStyle = { ...style };
    delete strippedStyle.color;

    return styled(Icon).attrs(({ disabled }) => ({
        style: {
            opacity: disabled ? 0.3 : 1,
            pointerEvents: disabled ? "none" : "auto",
            ...strippedStyle
        }
    }))`
        &&& {
            cursor: pointer;
            color: ${style.color ?? "#d3d3d3"};
            margin-left: 10px;
        
            &:hover {
                color: #50bbe6;
            }
        }
    `;
}

const UndoButton = getIconButton(UndoIcon);
const RedoButton = getIconButton(RedoIcon);
const HelpPopupIconButton = getIconButton(HelpIcon, { color: "#fff", marginRight: 30 });

const TemplateInfoPillContainer = styled.div`  
    width: 33%;

    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;  
`;

const TemplateInfoPill = styled.div`
    color: #FFFFFF;
    background: #11a9e2;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 3px 5px 3px 15px;
    border-radius: 18px;
    cursor: pointer;
`;

const TemplateInfoPillText = styled.div`
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.75px;
    text-align: center;
    text-transform: uppercase;
`;

const MenusContainer = styled.div`
    width: 33%;

    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;  
`;

const AvatarContainer = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 14px;

    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;  
    cursor: pointer;

    >img {
        max-width: 30px;
        max-height: 30px;
    }

    >svg {
        color: #ccc;
    }
`;

const PopupMenu = styled(Menu)`
    &&& {        
        .MuiPaper-root {
            ${({ width }) => width ? `width: ${width};` : ""}
            transform: translateY(12px) !important;
            overflow: visible;
            background: #fff;
            border-radius: 0;
        }
    }
`;

const PopupMenuTriangle = styled.div.attrs(({ left = "50%" }) => ({
    style: {
        left
    }
}))`
    position: absolute;
    top: 0;
    transform: translate(-50%, -100%);

    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;    
    border-bottom: 10px solid #fff;
`;

const TemplatePillPopupMenuDivider = styled(Divider)`
    &&& {
        margin: 4px 0;
    }
`;

const TemplatePillPopupMenuItem = styled(MenuItem)`
    &&& {
        height: 50px;

        >span.material-icons {
            font-size: 20px;
            opacity: 1;
        }

        &:hover {
            background-color: #50bbe6;
            color: #ffffff;

            >span.material-icons {
                color: #ffffff;
            }
        }
    }
`;

class PPTAddinEditorTopBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            canUndo: false,
            canRedo: false,
            isHelpPopupOpen: false,
            isTemplatePillPopupOpen: false
        };

        this.helpPopupIconButtonRef = React.createRef();
        this.switchTemplateButtonRef = React.createRef();
    }

    componentDidMount() {
        app.undoManager.on("undo redo reset undoStackChanged", this.updateUndoRedoState);

        this.updateUndoRedoState();
    }

    componentWillUnmount() {
        app.undoManager.off("undo redo reset undoStackChanged", this.updateUndoRedoState);
    }

    updateUndoRedoState = () => {
        const canUndo = app.undoManager.stackPosition !== -1;
        const canRedo = app.undoManager.stackPosition !== (app.undoManager.undoStack.length - 1);
        this.setState({ canUndo, canRedo });
    }

    handleUndo = () => {
        app.undoManager.undo();
    }

    handleRedo = () => {
        app.undoManager.redo();
    }

    handleOpenZendesk = () => {
        if (window.zendeskInit) {
            window.zendeskInit();
            window.zE(() => {
                window.zE.activate();
            });
        }

        this.setState({ isHelpPopupOpen: false });
    }

    handleShowFeedbackDialog = () => {
        ShowDialog(FeedbackDialog);
        this.setState({ isHelpPopupOpen: false });
    }

    handleShowShortcutsDialog = () => {
        this.setState({ isHelpPopupOpen: false });
        AppController.setExternalActivePanel(PanelType.SHORTCUTS);
    }

    handleSwitchTemplate = () => {
        const { canvas } = this.props;

        trackActivity("Slide", "ShowSwitchTemplate", null, null, { source: "editor:pptAddin" }, { audit: false });
        ShowDialog(SwitchTemplateDialog, { canvas });
        this.setState({ isTemplatePillPopupOpen: false });
    }

    handleConvertToClassic = async () => {
        const { canvas } = this.props;

        try {
            await canvas.convertToClassic();
            ShowConvertedToClassicDialog();
        } catch (err) {
            logger.error(err, "PPTAddinEditorTopBar canvas.convertToAuthoring() failed");
        }

        this.setState({ isTemplatePillPopupOpen: false });
    }

    handleOpenUserOptions = () => {
        const { onAccountClick } = this.props;
        onAccountClick();
    }

    render() {
        const { firebaseUser, currentSlideTemplate } = this.props;
        const { canUndo, canRedo, isHelpPopupOpen, isTemplatePillPopupOpen } = this.state;

        return (<>
            <Container>
                {/* Left size */}
                <LogoAndButtonsContainer>
                    <LogoContainer href="https://www.beautiful.ai" target="_blank">
                        <img src={getStaticUrl("/images/ui/b_icon_white.png")} />
                    </LogoContainer>
                </LogoAndButtonsContainer>

                {/* Right side */}
                <MenusContainer>
                    <HelpPopupIconButton
                        ref={this.helpPopupIconButtonRef}
                        onClick={() => this.setState(({ isHelpPopupOpen }) => {
                            isHelpPopupOpen = !isHelpPopupOpen;
                            if (isHelpPopupOpen) {
                                trackActivity("HelpCenter", "Open", null, null, { source: "editor:pptAddin" }, { audit: false });
                            }
                            return { isHelpPopupOpen };
                        })}
                    />
                    <AvatarContainer onClick={this.handleOpenUserOptions}>
                        {firebaseUser.photoURL && <img src={firebaseUser.photoURL} alt={firebaseUser.displayName ?? firebaseUser.email} />}
                        {!firebaseUser.photoURL && <PersonIcon />}
                    </AvatarContainer>
                </MenusContainer>
            </Container>

            {/* Template pill template popup */}
            <PopupMenu
                anchorEl={this.switchTemplateButtonRef.current}
                open={isTemplatePillPopupOpen}
                disableRestoreFocus
                TransitionProps={{
                    onExit: () => {
                        this.setState({ isTemplatePillPopupOpen: false });
                    }
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <PopupMenuTriangle />
                <MenuList>
                    <TemplatePillPopupMenuItem
                        onClick={this.handleSwitchTemplate}
                        disabled={!currentSlideTemplate?.canSwitchTemplate}
                    >
                        <Icon>change_circle</Icon>
                        <span>Switch Smart Slide...</span>
                    </TemplatePillPopupMenuItem>
                    {
                        WorkspaceController.actionPermissions[ActionPermissionsObject.CONVERT_TO_CLASSIC].use &&
                        <>
                            <TemplatePillPopupMenuDivider />
                            <TemplatePillPopupMenuItem
                                onClick={this.handleConvertToClassic}
                                disabled={!currentSlideTemplate?.canConvertToAuthoring}
                            >
                                <Icon>category</Icon>
                                <span>Convert to Classic Slide</span>
                            </TemplatePillPopupMenuItem>
                        </>
                    }
                </MenuList>
            </PopupMenu>

            {/* Help popup */}
            <PopupMenu
                anchorEl={this.helpPopupIconButtonRef.current}
                open={isHelpPopupOpen}
                disableRestoreFocus
                TransitionProps={{
                    onEnter: async () => {
                        const selectionLayerController = PresentationEditorController.getCurrentSelectionLayerController();
                        await selectionLayerController.setSelectedElements([]);
                    },
                    onExit: async () => {
                        this.setState({ isHelpPopupOpen: false });
                        const selectionLayerController = PresentationEditorController.getCurrentSelectionLayerController();
                        await selectionLayerController.setSelectedElements([]);
                    }
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                width="210px"
            >
                <PopupMenuTriangle left="144px" />
                <MenuList>
                    <MenuItem onClick={this.handleOpenZendesk}>
                        <Icon>support</Icon>
                        <span>Get Help</span>
                    </MenuItem>
                    <MenuItem onClick={this.handleShowFeedbackDialog}>
                        <EditIcon />
                        <span>Give Feedback</span>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={this.handleShowShortcutsDialog}>
                        <span>Keyboard Shortcuts</span>
                        <span style={{ marginLeft: 50 }}>⌘ ?</span>
                    </MenuItem>
                </MenuList>
            </PopupMenu>
        </>);
    }
}

export default PPTAddinEditorTopBar;
